{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{waitscreen} = require 'src/js/infrastructure'

setup = ($ctx) ->
    $startDatePicker = $ctx.find('#start-date-picker')
    $startDatePicker.datetimepicker()

    $endDatePicker = $ctx.find('#end-date-picker')
    $endDatePicker.datetimepicker()
    # useCurrent : false #Important! See issue #1075

    startDate = $startDatePicker.data('DateTimePicker').date()
    endDate = $endDatePicker.data('DateTimePicker').date()

    # set today as the maximum for both dates
    $startDatePicker.data('DateTimePicker').maxDate(endDate)
    $endDatePicker.data('DateTimePicker').maxDate(endDate).minDate(startDate)
    $updateOverviewBtn = $ctx.find('#update-overview')

    $updateOverviewBtn.on 'click', -> getUtilization $('body')
    $startDatePicker.on 'dp.change', (e) ->
        $endDatePicker.data('DateTimePicker').maxDate(moment()).minDate(e.date)
        startDate = $startDatePicker.data('DateTimePicker').date()
        endDate = $endDatePicker.data('DateTimePicker').date()
        if startDate and endDate
            $updateOverviewBtn.enable()
        else
            $updateOverviewBtn.disable()

    $endDatePicker.on 'dp.change', (e) ->
        $startDatePicker.data('DateTimePicker').maxDate(e.date)
        $endDatePicker.data('DateTimePicker').maxDate(moment())
        # maxdate can't be higher than today, because the start date picker has today as the maximum
        startDate = $startDatePicker.data('DateTimePicker').date()
        endDate = $endDatePicker.data('DateTimePicker').date()
        if startDate and endDate
            $updateOverviewBtn.enable()
        else
            $updateOverviewBtn.disable()

    $table = $ctx.find('#utilization-grid')
    $table.staticgrid()[0].table.column(0).order('desc').draw()
    $table.on 'change.selection', gridRowSelectionChanged

    $detailsModal = $ctx.find('#utilization-details-modal')
    $detailsModal.on 'shown.bs.modal', refreshDetailsModal

    getUtilization $ctx


gridRowSelectionChanged = (event) ->
    $detailsButton = $('#details-button')

    if event.rows.length > 0
        $detailsButton.parent().popover('destroy')
        $detailsButton.enable()
    else
        $detailsButton.parent().popover('enable')
        $detailsButton.disable()


refreshDetailsModal = ->
    selectedRow = $('#utilization-grid').staticgrid()[0].state.rows('.selected')[0]
    $detailsModal = $('#utilization-details-modal')

    # expected to get a moment here, but it was a string
    title = $detailsModal.data('title').replace '{Date}', moment(selectedRow.date).format('L')
    $detailsModal.find('h4.modal-title').text title

    detailsGrid = $('#utilization-details-grid').staticgrid()[0]
    detailsGrid.table.rows().remove()
    detailsGrid.table.rows.add(selectedRow.details).draw()
    detailsGrid.table.columns.adjust()

getUtilization = ($ctx) ->
    $parameters = $ctx.find '#utilization-parameters'

    url = $parameters.data 'url'
    data =
        startDate: $ctx.find('#start-date-picker').data('DateTimePicker').date()
        endDate: $ctx.find('#end-date-picker').data('DateTimePicker').date()

    promise = antiForgeryPost url, data, $ctx
        .done (utilization) ->
            refreshUtilizationGrid $('body'), utilization
        .fail makeErrorHandler $parameters
    waitscreen.waitFor promise

refreshUtilizationGrid = ($ctx, utilization) ->
    grid = $ctx.find('#utilization-grid').staticgrid()[0]
    grid.table.rows().deselect()
    grid.table.rows().remove()

    # prepare the ajax response to work with both grids
    for row in utilization
        row.date = moment row.date
        for detail in row.details
            detail.logon = moment detail.logon
            detail.logoff = moment detail.logoff

    grid.table.rows.add(utilization).draw()
    grid.table.columns.adjust()

register('App.RTK.Administration.VRSLoginManagement.UtilizationOverview.Setup', setup)
