{Plugin} = require './base'

Overlay = require('ol/Overlay').default
{transform, transformExtent} = require('ol/proj')

{config} = require 'src/js/infrastructure'

class LocationSearchPlugin extends Plugin
    constructor: (@$ctx, @withPointerOvelay = true) ->
    init: ->
        @searchUrl = "https://dev.virtualearth.net/REST/v1/Locations?o=json&key=#{config.bingApiKey}&q="
        $button = @$ctx.find '#mapSearchButton'
        $button.on 'click', @startSearch
        @$input = @$ctx.find('#mapSearchInput')
        @$input.keyup @searchOnEnter
        if @withPointerOvelay
            @positionOverlay = @createOverlay()
            @map.addOverlay @positionOverlay

    createOverlay: () ->
        $positionDiv = $('<div>')
        $positionDiv.hide()
        $positionIcon = $('<i>').addClass('fa fa-map-marker fa-2x text-danger mapLokationMarker').attr('id', 'mapPosition')
        $positionDiv.append  $positionIcon
        mapTarget = @map.getTarget()
        $(mapTarget).append($positionDiv)
        overlayOptions =
            element:  $(mapTarget).find('i#mapPosition')[0]
            positioning: 'bottom-center'
            stopEvent: false
        return new Overlay(overlayOptions)

    searchOnEnter: (event) =>
        if event.keyCode is 13
            @startSearch()

    startSearch: () =>
        searchString = @$input.val()
        @search(searchString)

    search: (searchString) =>
        error = (data, textStatus, errorThrown ) ->
             toastr.error "", "Couldn't access Bingmaps!"
        if searchString
            ajaxData =
                type: 'POST'
                contentType: 'application/json; charset=utf-8'
                url: @searchUrl+searchString
                success: @searchCallback
                error:  error,
                dataType: "jsonp",
                jsonp: "jsonp"

            $.ajax ajaxData

    searchCallback: (data) =>
        if data.statusCode isnt 200
             toastr.error "", "Bing Server Error"
             return
         if !data.resourceSets or data.resourceSets[0].estimatedTotal is 0
            toastr.warning "", "No Location Found!"
            return
        @goToFoundExtent data
        if @withPointerOvelay
            @showFoundLocation data

    goToFoundExtent: (data) =>
        extent_WGS84 = @transformBingBBoxToOlExtent data.resourceSets[0].resources[0].bbox
        extent_900913 = transformExtent extent_WGS84, 'EPSG:4326', 'EPSG:900913'
        view = @map.getView()
        view.fit extent_900913

    transformBingBBoxToOlExtent : (bbox) =>
        olExtent = []
        olExtent[0] = bbox[1]
        olExtent[1] = bbox[0]
        olExtent[2] = bbox[3]
        olExtent[3] = bbox[2]
        return olExtent

    transformBingCoordinatesToOlCoordinates: (bingCoordinates) =>
        olCoordinates =[]
        olCoordinates[0] = bingCoordinates[1]
        olCoordinates[1] = bingCoordinates[0]
        return olCoordinates

    showFoundLocation: (data) =>
        location_WGS84 = @transformBingCoordinatesToOlCoordinates data.resourceSets[0].resources[0].point.coordinates
        location_900913 = transform location_WGS84, 'EPSG:4326', 'EPSG:900913'
        @positionOverlay.setPosition location_900913

module.exports = {
    LocationSearchPlugin
}

module.exports.__esModule = true
module.exports.default = LocationSearchPlugin
