{register} = require 'src/js/infrastructure/setup-registry'
{post} = require 'src/coffee/infrastructure/ajax'
{waitscreen} = require 'src/js/infrastructure'

{makeReplaceHandler} = require 'src/coffee/helpers/async/fragment-loading'
{makeErrorHandler, makeWarningHandler} = require 'src/coffee/helpers/async/notifications'

{getByName} = require 'src/coffee/controls/map'

{RTKMachinePathMapPlugin} = require 'src/coffee/areas/rtk/rtk-machine-path-map-plugin'

machinePathMapSetup = ($ctx) ->
    $logins = $ctx.find('#logins')
    $showPathBtn = $ctx.find('#show-path')

    $startDatePicker = $ctx.find('#start-datepicker')
    startDatePicker = $startDatePicker.data('DateTimePicker')
    $endDatePicker = $ctx.find('#end-datepicker')
    endDatePicker = $endDatePicker.data('DateTimePicker')
    infoTextEndDateChanged = $('#end-date-changed').val()
    infoTextStartDateChanged = $('#start-date-changed').val()

    toggleButtons()

    $csvDownloadButton = $ctx.find('#csv-download-button').on 'click', downloadCsv

    $startDatePicker.on 'dp.hide', (e) ->
        if (startDatePicker.date() and endDatePicker.date())
            newEndDate = e.date.clone()
            endDate = endDatePicker.date()

            endDatePicker.maxDate(moment().subtract(30, 'seconds')).minDate(newEndDate.subtract(30, 'seconds'))

            if endDate.diff(newEndDate, 'days', true) > 7
                newEndDate.add(7, 'days')
                now = moment()

                if newEndDate.diff(now) > 0
                    endDatePicker.date(now)
                    makeWarningHandler(infoTextEndDateChanged)()
                else
                    endDatePicker.date(newEndDate)
                    makeWarningHandler(infoTextEndDateChanged)()

        toggleButtons()

    $endDatePicker.on 'dp.hide', (e) ->
        if (startDatePicker.date() and endDatePicker.date())
            endDate = e.date.clone()
            startDate = startDatePicker.date()

            startDatePicker.maxDate(endDate)
            endDatePicker.maxDate(moment().subtract(30, 'seconds'))

            if endDate.diff(startDate, 'days', true) > 7
                endDate.subtract(7, 'days')
                startDatePicker.date(endDate)
                makeWarningHandler(infoTextStartDateChanged)()

        toggleButtons()

    $logins.on 'change', toggleButtons
    $showPathBtn.on 'click', updateMachinePath


downloadCsv = (event) ->
    event.preventDefault()

    $btn = $(event.currentTarget)
    url = $btn.data 'url'
    data =
        loginName: $('#logins').val()
        startDate: $('#start-datepicker').data('DateTimePicker').date()
        endDate: $('#end-datepicker').data('DateTimePicker').date()

    promise = post url, data
        .done (downloadUrl) -> window.location = downloadUrl
        .fail makeErrorHandler $btn

    waitscreen.waitFor promise

toggleButtons = ->
    startDate = $('#start-datepicker').data('DateTimePicker').date()
    endDate = $('#end-datepicker').data('DateTimePicker').date()
    loginName = $('#logins').val()

    $showPathButton = $('#show-path')
    $csvDownloadButton = $('#csv-download-button')

    if startDate and endDate and loginName
        $showPathButton.enable()
        $csvDownloadButton.enable()
    else
        $showPathButton.disable()
        $csvDownloadButton.disable()

updateMachinePath = ->
    url = $('#show-path').data 'url'
    data =
        loginName: $('#logins').val()
        startDate: $('#start-datepicker').data('DateTimePicker').date()
        endDate: $('#end-datepicker').data('DateTimePicker').date()

    promise = post url, data
        .done makeReplaceHandler $('#map-container')
    waitscreen.waitFor promise

machinePathMapPluginSetup = ($ctx) ->
    map = getByName 'machine-details', $ctx
    layerName = $('#layer-name').data 'layer-name-points'
    map.registerPlugin new RTKMachinePathMapPlugin($ctx, layerName)

register('App.RTK.MachinePathMap.Setup', machinePathMapSetup)
register('App.RTK.MachinePathMap.PluginSetup', machinePathMapPluginSetup)
