{Plugin} = require 'src/coffee/controls/map/plugins/base'

Overlay = require('ol/Overlay').default
VectorSource = require('ol/source/Vector').default

class RTKMachinePathMapPlugin extends Plugin
    constructor: ($ctx, layerName) ->
        @$ctx = $ctx
        @layerName = layerName

    init: ->
        @layerPoint = @mapcontrol.layerManager.getLayersByName @layerName

        if @layerPoint.length > 0
            @layerPoint[0].setMaxResolution 3

        @dateTimeInfoSource = new VectorSource
        @setupOverlay()
        @map.on 'pointermove', (evt) =>
            @dateTimeInfoSource.clear()
            @overlay.setPosition(undefined)
            @map.forEachFeatureAtPixel evt.pixel, @showDateTime

    showDateTime: (feature, layer) =>
        return unless layer and layer.get('name') is @layerName
        properties = feature.getProperties()
        dateTime = moment(properties.dateTime).format('L LT')
        quality = properties.quality
        numberOfSatellites = properties.numberOfSatellites
        pointGeometry = feature.getGeometry().getCoordinates()
        @$toolTipContent.find('#date-time').html(dateTime)
        @$toolTipContent.find('#quality').html(quality)
        @$toolTipContent.find('#number-of-satellites').html(numberOfSatellites)

        @overlay.setPosition(pointGeometry)
        @$toolTipContainer.show()

    setupOverlay: ->
        @$toolTipContainer = @$ctx.find('#tooltip-container')
        @$toolTipContent = @$toolTipContainer.find('#tooltip-content')
        @overlay = new Overlay
            element: @$toolTipContainer[0]
        @map.addOverlay @overlay

module.exports = {
    RTKMachinePathMapPlugin
}
