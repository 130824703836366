{getCorrectionFactorForLength} = require 'src/coffee/controls/map/utilities/coordinates'
{Plugin} = require 'src/coffee/controls/map/plugins/base'

Feature = require('ol/Feature').default
Circle = require('ol/geom/Circle').default
VectorLayer = require('ol/layer/Vector').default
VectorSource = require('ol/source/Vector').default

class RadiusPlugin extends Plugin
    constructor: (pointVectorLayer) ->
        @pointVectorLayer = pointVectorLayer

    init: ->
        @radiusSource = new VectorSource
        @radiusVectorLayer = new VectorLayer
            map: @map
            source: @radiusSource
        @createRadiusOverlays()
        pointSource = @pointVectorLayer.getSource()
        pointSource.on 'addfeature', @newPoint
        pointSource.on 'changefeature', @pointMoved
        pointSource.on 'removefeature', @pointDeleted

    createRadiusOverlays: ->
        for pointFeature in @pointVectorLayer.getSource().getFeatures()
            @createRadiusOverlay pointFeature

    createRadiusOverlay: (pointFeature) ->
        circleFeature = new Feature
            geometry: @createRadiusGeometry pointFeature
        circleFeature.setId pointFeature.getId()
        @radiusSource.addFeature circleFeature

    createRadiusGeometry: (pointFeature) ->
        center = pointFeature.getGeometry().getCoordinates()
        radius = pointFeature.get('radius')
        unless radius
            radius = $('#radius').val()
        radius = radius * 1000 / getCorrectionFactorForLength(@map)
        return new Circle center, radius

    newPoint: (evt) =>
        @createRadiusOverlay(evt.feature)

    pointMoved: (evt) =>
        raduisFeature = @radiusSource.getFeatureById(evt.feature.getId())
        raduisFeature.setGeometry @createRadiusGeometry(evt.feature)

    pointDeleted: (evt) =>
        raduisFeature = @radiusSource.getFeatureById(evt.feature.getId())
        @radiusSource.removeFeature raduisFeature

module.exports = {
    RadiusPlugin
}
