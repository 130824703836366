{register} = require 'src/js/infrastructure/setup-registry'
{getByName} = require 'src/coffee/controls/map'

{RTKTelematicPlugin} = require 'src/coffee/areas/rtk/rtk-telematic-plugin'
{RTKMachineToolTipInfoPlugin} = require 'src/coffee/areas/rtk/rtk-machine-tool-tip-info-plugin'

telematicSetup = ($ctx, promise) ->
    layerName = $ctx.find('#update-positions').data 'layer-name'
    map = getByName 'telematic-details', $ctx
    map.registerPlugin new RTKTelematicPlugin($ctx, layerName, promise)
    map.registerPlugin new RTKMachineToolTipInfoPlugin($ctx, layerName, promise)

register('App.RTK.Evaluation.Setup', telematicSetup)
