{register} = require 'src/js/infrastructure/setup-registry'
{post, antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{waitscreen} = require 'src/js/infrastructure'

setup = ($ctx) ->
    $grid = $ctx.find('#licenses-grid').staticgrid()[0]

    $detailsGrid = $ctx.find('#utilization-details-grid').staticgrid()[0]
    $duration = $ctx.find('#duration')
    $startDatePicker = $ctx.find('#start-date-picker')
    $startDatePicker.datetimepicker()
    $utilizationButton = $ctx.find('#show-utilization-button')
    $endDatePicker = $ctx.find('#end-date-picker')
    $endDatePicker.datetimepicker
    # useCurrent : false #Important! See issue #1075

    $title = $ctx.find('#utilization-details-modal h4.modal-title')
    startDate = $startDatePicker.data('DateTimePicker').date()
    endDate = $endDatePicker.data('DateTimePicker').date()
    $parameters = $ctx.find('#utilization-parameters')
    $updateBtn = $ctx.find('#update-details')
    # set today as the maximum for both dates
    $startDatePicker.data('DateTimePicker').maxDate(endDate)
    $endDatePicker.data('DateTimePicker').maxDate(endDate).minDate(startDate)
    $updateBtn.on 'click', ->
        startDate = $startDatePicker.data('DateTimePicker').date()
        endDate = $endDatePicker.data('DateTimePicker').date()
        unless startDate is null or endDate is null
            getUtilization()
    $startDatePicker.on 'dp.change', (e) ->
        $endDatePicker.data('DateTimePicker').maxDate(moment()).minDate(e.date)
#        startDate = $startDatePicker.data('DateTimePicker').date()
#        endDate = $endDatePicker.data('DateTimePicker').date()
#        if startDate not null and endDate not null
#            getUtilization()

    $endDatePicker.on 'dp.change', (e) ->
        $startDatePicker.data('DateTimePicker').maxDate(e.date)
        $endDatePicker.data('DateTimePicker').maxDate(moment())
        # maxdate can't be higher than today, because the start date picker has today as the maximum
#        startDate = $startDatePicker.data('DateTimePicker').date()
#        endDate = $endDatePicker.data('DateTimePicker').date()
#        if startDate not null and endDate not null
#            getUtilization()

    $detailsModal = $ctx.find('#utilization-details-modal')


    $('#csv-export-button').on 'click', (event) ->
        $btn = $(event.currentTarget)
        promise = post $btn.data 'url'
            .done (url) -> window.location = url
            .fail makeErrorHandler $btn

            waitscreen.waitFor promise

    selectedLicenseChanged = (event) ->
        if event.rows.length > 0
            $utilizationButton.parent().popover('destroy')
            $utilizationButton.enable()
        else
            $utilizationButton.parent().popover('enable')
            $utilizationButton.disable()

    refreshDetailsModal = ->
        selectedRow = $grid.state.rows('.selected')[0]
        $title.text selectedRow.rtkUserName

        getUtilization()


    getUtilization =  ->
        $duration.val null

        url = $parameters.data 'url'
        data =
            rtkUserName: $grid.state.rows('.selected')[0].rtkUserName
            startDate: $startDatePicker.data('DateTimePicker').date()
            endDate: $endDatePicker.data('DateTimePicker').date()

        promise = antiForgeryPost url, data
            .done (utilization) ->
                refreshUtilizationDetailsGrid utilization
            .fail makeErrorHandler $parameters

        waitscreen.waitFor promise

    refreshUtilizationDetailsGrid = (utilization) ->
        $detailsGrid.table.rows().deselect()
        $detailsGrid.table.rows().remove()

        for row in utilization.rows
            row.logon = moment row.logon
            row.logoff = if row.logoff then moment row.logoff else null

        $detailsGrid.table.rows.add(utilization.rows).draw()
        $detailsGrid.table.columns.adjust()

        $duration.val utilization.duration
        $duration.text utilization.duration

    $grid.table.on 'change.selection', selectedLicenseChanged
    $detailsModal.on 'shown.bs.modal', refreshDetailsModal

register('App.RTK.Administration.MyLicenses.Setup', setup)
