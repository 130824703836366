require './my-licenses'
require './radius-map-plugin'
require './rtk'
require './rtk-administration'
require './rtk-evaluation-setup'
require './rtk-machine-path-map-plugin'
require './rtk-machine-path-map-setup'
require './rtk-machine-tool-tip-info-plugin'
require './rtk-plans'
require './rtk-telematic-plugin'
require './vrs-login-management'
require './vrs-utilization-overview'
require './my-licenses-for-entity-setup'
