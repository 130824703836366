{register} = require 'src/js/infrastructure/setup-registry'
{LocationSearchPlugin} = require 'src/coffee/controls/map/plugins/location-search'
{RadiusPlugin} = require 'src/coffee/areas/rtk/radius-map-plugin'
{findNode, findParent} = require 'src/coffee/controls/tree/helpers'
{getByName} = require 'src/coffee/controls/map'

indexSetup = ($ctx, promise) ->
    companyId = $ctx.find('#defaultEntityId').val()
    unless companyId
        return
    tree = $ctx.find '.easytree'
    .data 'easytree'
    nodes = tree.getAllNodes()
    node = findNode nodes, (n) -> n.data.uid is companyId
    if node
      expandNodeId = node.id

    loop
        parent = findParent nodes, expandNodeId
        break if parent is null
        parent.isExpanded = true
        expandNodeId = parent.id

    tree.rebuildTree()
    if node
      tree.activateNode node.id
    promise.done ->
        $ "a[href='#{node.href}']"
        .click()


mapSetup = ($ctx) ->
    map = getByName 'RTKMap', $ctx

    layer = map.layerManager.layers.webgis()[0] or map.layerManager.layers.vector()[0]
    map.registerPlugin new LocationSearchPlugin($ctx, false)
    map.registerPlugin new RadiusPlugin layer

    if map.layerManager.layers.webgis()[0]
        map.layerManager.layers.webgis()[0].getSource().on 'changefeature', updateLatLon
        map.layerManager.layers.webgis()[0].getSource().on 'addfeature', updateLatLon
        map.layerManager.layers.webgis()[0].getSource().on 'removefeature', clearLatLon

    $updateBtn = $ctx.find('#update-position')
    $updateBtn.on 'click', updatePosition
    $lon = $ctx.find('#longitude')
    $lat = $ctx.find('#latitude')

    $lon.on 'input', ->
        if $lon.val().length is 0
            $updateBtn.disable()
        else
            $updateBtn.enable()
    $lat.on 'input', ->
        if $lat.val().length is 0
            $updateBtn.disable()
        else
            $updateBtn.enable()

updatePosition = ->
    radius = $('#radius').val()
    parser = Globalize.numberParser()
    longitude = parser($('#longitude').val())
    latitude = parser($('#latitude').val())

    map = getByName 'RTKMap'
    unless map
        return
    features = map.layerManager.layers.webgis()[0].getSource().getFeatures()
    unless features.length isnt 0
        return

    geo = features[0].getGeometry()
    geo.setCoordinates([longitude, latitude])
    geo.transform('EPSG:4326', 'EPSG:900913')

    features[0].set 'radius', radius
    radiusPlugin = map.plugins[map.plugins.length - 1]
    radiusPlugin.pointMoved
        feature: features[0]
    view = map.map.getView()
    view.setCenter(geo.getCoordinates())
    extent = geo.getExtent()
    parsedRadius = parser(radius)
    extent[0] = extent[0] - parsedRadius * 1000
    extent[1] = extent[1] - parsedRadius * 1000
    extent[2] = extent[2] + parsedRadius * 1000
    extent[3] = extent[3] + parsedRadius * 1000
    map.changeDefaultExtent extent

clearLatLon = ->
    $('#longitude').val("")
    $('#latitude').val("")

updateLatLon = (evt) ->
    center = evt.feature.getGeometry().clone()
    center.transform('EPSG:900913', 'EPSG:4326')
    coords = center.getCoordinates()
    $('#longitude').val(Globalize.formatNumber(coords[0], {minimumFractionDigits: 15}))
    $('#latitude').val(Globalize.formatNumber(coords[1], {minimumFractionDigits: 15}))

register('App.RTK.IndexSetup', indexSetup)
register('App.RTK.MapSetup', mapSetup)
