{register} = require 'src/js/infrastructure/setup-registry'
{get, antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{makeReplaceHandler, makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{waitscreen} = require 'src/js/infrastructure'

{getByName} = require 'src/coffee/controls/map'

postionSaveSetup = ($ctx) ->
    $saveButton = $ctx.find '#mapSaveButton'
    $saveButton.on 'click', savePosition
    numberOfAllowedPosChangesPerYear = parseInt $ctx.find('#NumberOfAllowedPosChangesPerYear').val()
    numberOfPosChangesPerYear = parseInt $ctx.find('#NumberOfPosChangesPerYear').val()

    if numberOfPosChangesPerYear >= numberOfAllowedPosChangesPerYear
        $saveButton.disable()
savePosition = (e) ->
    $saveBtn = $ e.currentTarget
    map = getByName 'RTKMap'
    data =
        states: map.webgis.getModifiedGeometriesWKT()
        planId: map.layerManager.layers.webgis()[0].get 'uid'
        entityId: $('#entityId').val()
        entityName: $('#entityName').val()
        planType: $('#planType').val()

    url = $saveBtn.data 'url'

    promise = antiForgeryPost url, data
        .then makeRefreshHandler()
        .then makeSuccessHandler $saveBtn
        .fail makeErrorHandler $saveBtn
    waitscreen.waitFor promise

setupPlanLoginGrid = ($ctx) ->
    grid = $ctx.find('#RTKLoginsGrid').staticgrid()[0]
    editor = grid.editor
    editor.on 'initEdit', ->
        editor.show()
        editor.hide('username')
        editor.hide('mountpoint')
        editor.hide('password')
        editor.hide('port')
        editor.hide('serverAddress')

    grid.table.on 'select', ( e, dt, type, indexes) ->
        $('#openPasswordChangeModal').enable()
        uid = grid.table.rows(indexes).data().pluck('uid')[0]
        $('a#changePasswordButton').data 'loginId', uid

    grid.table.on 'deselect', ->
        $('#openPasswordChangeModal').disable()

    $('#openPasswordChangeModal').disable()

    editor.on 'postEdit', savelocalBaseLogins

    availableVehicles = JSON.parse $ctx.find('#available-vehicles').html()

    editor.on 'preOpen', (e, mode, action) ->
        field = editor.field('fkVehicle')
        selectize = field.node().querySelector('select').selectize
        selected = selectize.options[selectize.items[0]]
        selectize.clear(true)
        selectize.clearOptions()

        if selected
            selectize.addOption(selected)
            selectize.addItem(selected.value, true)

        for vehicle in availableVehicles
            selectize.addOption(vehicle)
        selectize.refreshOptions(false)

savelocalBaseLogins = ->
    saveText = $('#saveSuccess').val()
    saveError = $('#saveError').val()
    url =  $('#save-url').data 'url'
    grid = $('#RTKLoginsGrid').staticgrid()[0]
    data =
        logins: grid.state.changes()
        planType: $('#PlanType').val()
        planId: $('#PlanId').val()
        entityName: $('#EntityName').val()

    promise = antiForgeryPost url, data
        .then makeRefreshHandler()
        .then grid.state.reset()
        .done makeSuccessHandler saveText
        .fail makeErrorHandler saveError
    waitscreen.waitFor promise


loginsDetailSetup = ($ctx) ->
    $detailButton = $ctx.find('#detail')
    url = $detailButton.data 'url'
    $grid = $ctx.find('#logins-grid')

    table = $grid.staticgrid()[0].table

    $grid.on 'change.selection', ->
        if table.rows('.selected').data().toArray().length > 0
            $detailButton.enable()
        else
            $detailButton.disable()
    $detailButton.on 'click', ->
        data =
            entityName: $('#EntityName').val()
            loginId: table.row('.selected').data().uid
            planId: table.row('.selected').data().fkrtK_Plan
        promise = get url, data
            .done makeReplaceHandler $('#content')
        waitscreen.waitFor promise

loginListSetup = ($ctx, promise) ->
    availableVehicles = JSON.parse $ctx.find('#available-vehicles').html()

    $selects = $ctx.find('select[name=FKVehicle]')
    selectizes = $selects.toArray().map((x) -> x.selectize)

    updateLastSelected = (selectize) ->
        selected = selectize.options[selectize.items[0]]
        selectize.lastSelected = selected

    for selectize in selectizes
        do (selectize) ->
            updateLastSelected(selectize)

            selectize.on 'change', (value) ->
                if selectize.lastSelected
                    availableVehicles.push(selectize.lastSelected)
                updateLastSelected(selectize)

                availableVehicles = availableVehicles.filter((x) -> x.value isnt value)

                updateSelects()

    updateSelects = ->
        for selectize in selectizes
            selected = selectize.options[selectize.items[0]]
            selectize.clear(true)
            selectize.clearOptions()

            if selected
                selectize.addOption(selected)
                selectize.addItem(selected.value, true)

            for vehicle in availableVehicles
                selectize.addOption(vehicle)
            selectize.refreshOptions(false)

    promise.then updateSelects


register('App.RTK.Plans.SetupPlanLoginGrid', setupPlanLoginGrid)
register('App.RTK.Plans.PostionSaveSetup', postionSaveSetup)
register('App.RTK.Plans.LoginsDetailSetup', loginsDetailSetup)
register('App.RTK.Plans.LoginListSetup', loginListSetup)
