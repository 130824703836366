{register} = require 'src/js/infrastructure/setup-registry'
{post, antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{waitscreen} = require 'src/js/infrastructure'

setup = ($ctx) ->
    $resetButton = $ctx.find('#reset-logins')
    grid = $ctx.find('#vrs-login-grid').staticgrid()[0]
    grid.editor.on 'initEdit', ->
        grid.editor.hide('lastUsed')
        grid.editor.hide('loginName')
        grid.editor.hide('sessionIdFirst')
        grid.editor.hide('sessionIdSecond')
        grid.editor.hide('fkMyRTKBaseStation')
        grid.editor.hide('fkMyRTKBaseLogin')

    grid.editor.on 'initCreate', ->
        grid.editor.field('licenseBegin').val(moment())
        grid.editor.field('licenseEnd').val(moment())
        grid.editor.hide('lastUsed')
        grid.editor.hide('loginName')
        grid.editor.hide('sessionIdFirst')
        grid.editor.hide('sessionIdSecond')
        grid.editor.hide('fkMyRTKBaseStation')
        grid.editor.hide('fkMyRTKBaseLogin')

    $saveButton = $ctx.find('#save-button')
    $saveButton.on 'click', ->
        url = $saveButton.data 'url'
        data =
            changes: grid.state.changes()

        promise = antiForgeryPost url, data
            .then makeRefreshHandler()
            .done makeSuccessHandler $saveButton
            .fail makeErrorHandler $saveButton
        waitscreen.waitFor promise

    grid.table.on 'change.selection', (event) ->
        if event.rows.length < 1
            $resetButton.disable()
        else

            $resetButton.enable()
    $resetButton.on 'click', ->
        selectedRows = grid.table.rows('.selected').data().toArray()
        vrsLoginIds = selectedRows.map((x) -> x.uid)

        url = $resetButton.data 'url'
        if vrsLoginIds.length > 0
            promise = post url, {vrsLoginIds}
                .then makeRefreshHandler()
                .done makeSuccessHandler $resetButton
                .fail makeErrorHandler $resetButton
            waitscreen.waitFor promise

register('App.RTK.Administration.VRSLoginManagement.LoginManagement.Setup', setup)
