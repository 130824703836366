{waitscreen} = require 'src/js/infrastructure'
{notifications} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{get, antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeReplaceHandler, makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'
{makeSuccessHandler, makeErrorHandler, makeWarningHandler} = require 'src/coffee/helpers/async/notifications'

{getByName} = require 'src/coffee/controls/map'

WKT = require('ol/format/WKT').default

checkSaveState = ($ctx = $('#content')) ->
    $saveButton = $ctx.find('#saveCustomerDetails')
    currentPlanType = $ctx.find('#currentPlanType').val()
    globalPlanType = $ctx.find('#globalPlanType').val()
    planType = $ctx.find('#PlanType').val()
    planId = $ctx.find('#planId').val()
    $daysDropdown = $ctx.find('#days-dropdown')
    $monthsDropdown = $ctx.find('#months-dropdown')
    $yearsDropdown = $ctx.find('#years-dropdown')
    endDate = $ctx.find('#licenseEnd').data('DateTimePicker').date()
    startDate = $ctx.find('#licenseBegin').data('DateTimePicker').date()
    countLogins = 0
    numberOfLicences = 0
    rtkRadiusIsSet = true
    renewalsSelected = renewalSelected($daysDropdown, $monthsDropdown, $yearsDropdown)
    hasStartAndEndDate = false
    if endDate and startDate
        hasStartAndEndDate = true

    if planType
        countLogins = $ctx.find('#customerLoginsGrid').staticgrid()[0].table.data().length
        if currentPlanType isnt globalPlanType
            map = getByName 'RTKMap'
            source = map.layerManager.layers.webgis()[0].getSource()
            features = source.getFeatures()
            rtkRadiusIsSet = features?.length > 0
        numberOfLicences = parseInt $ctx.find('#NumberOfLicences').val()

    updatePopoverMessage($saveButton, countLogins, rtkRadiusIsSet, numberOfLicences, planId, renewalsSelected, hasStartAndEndDate)

    canSaveInRenewalMode = true
    if planId
        canSaveInRenewalMode = !renewalsSelected

    enableSaveBtn = currentPlanType and endDate and startDate and rtkRadiusIsSet and (numberOfLicences > 0) and (numberOfLicences >= countLogins) and (countLogins > 0) and canSaveInRenewalMode
    if enableSaveBtn
        $saveButton.enable()
    else
        $saveButton.disable()

updatePopoverMessage = ($saveButton, countLogins, rtkRadiusIsSet, numberOfLicences, planId, renewalsSelected, hasStartAndEndDate) ->
    noLoginsText = $saveButton.data 'no-logins-message'
    maxLogins = $saveButton.data 'max-logins'
    noPositionText = $saveButton.data 'no-position-message'
    noPositionAndLoginText = $saveButton.data 'no-position-login-message'
    renewalSelected = $saveButton.data 'renewal-selected'
    noRuntimeSelected = $saveButton.data 'no-runtime-selected'
    noStartEndDateSet = $saveButton.data 'no-start-end-date-set'
    message = ''
    if(countLogins is 0 and not rtkRadiusIsSet)
        message = noPositionAndLoginText
    else if (countLogins > numberOfLicences)
        message = maxLogins
    else if (countLogins is 0)
        message = noLoginsText
    else if (countLogins <= numberOfLicences and not rtkRadiusIsSet)
        message = noPositionText
    else if renewalsSelected and hasStartAndEndDate
        if planId
            message = renewalSelected
    else if not renewalsSelected and (countLogins > 0)
        unless planId
            message = noRuntimeSelected
    else if not hasStartAndEndDate
        message = noStartEndDateSet
    else
        message = ''

    if message
        $saveButton.disable().parent().popover().attr('data-content', message)
    else
        $saveButton.enable().parent().popover('destroy')

setupCustomerDetails = ($ctx, $promise) ->
    $newStartDateBtn = $ctx.find('#newStartDateButton')
    $correctionDataCheckbox = $ctx.find('#no-correction-data')
    hasAdminRights = JSON.parse($ctx.find('#hasAdminRights').data('value').toLowerCase())
    $planDetail = $ctx.find('#PlanType')
    $useExternalServiceSelectize = $ctx.find('#external-service')
    selectize = $useExternalServiceSelectize.selectize()[0].selectize
    $externalPlanTypeValue = $ctx.find('#externalPlanTypeValue')
    planId = $ctx.find('#planId').val()
    $saveButton = $ctx.find('#saveCustomerDetails')

    $startDateTimePicker = $ctx.find('#licenseBegin')
    startDateTimePicker = $startDateTimePicker.data('DateTimePicker')

    $endDatePicker = $ctx.find('#licenseEnd')
    endDateTimePicker = $endDatePicker.data('DateTimePicker')

    $daysDropdown = $ctx.find('#days-dropdown')
    $monthsDropdown = $ctx.find('#months-dropdown')
    $yearsDropdown = $ctx.find('#years-dropdown')

    daysLicensingPeriodSelect = $daysDropdown.selectize()
    daysLicensingPeriodSelectize = daysLicensingPeriodSelect[0].selectize

    monthsLicensingPeriodSelect = $monthsDropdown.selectize()
    monthsLicensingPeriodSelectize = monthsLicensingPeriodSelect[0].selectize

    yearsLicensingPeriodSelect = $yearsDropdown.selectize()
    yearsLicensingPeriodSelectize = yearsLicensingPeriodSelect[0].selectize

    $correctionDataCheckbox.on 'click', ->
        $correctionDataCheckbox.data('default-value', $correctionDataCheckbox.prop('checked'))

    $newStartDateBtn.on 'click', ->
        $('#new-license-begin').data('DateTimePicker').minDate(moment().startOf('day'))

    defaultEndDate = null

    if planId
        defaultEndDate = endDateTimePicker.date()
    else
        startDateTimePicker.minDate(moment().startOf('day'))

    updateExternalServiceAvailability = (hasAdminRights) ->
        if $externalPlanTypeValue.data('value') is parseInt($planDetail.val())
            $useExternalServiceSelectize.rules('add', 'required')
            $correctionDataCheckbox.prop('checked', false)
            $correctionDataCheckbox.disable()
            selectize.addItem($useExternalServiceSelectize.data('default-value'), true)
            if hasAdminRights
                selectize.enable()
        else
            $useExternalServiceSelectize.rules('remove', 'required')
            $correctionDataCheckbox.prop('checked', JSON.parse($correctionDataCheckbox.data('default-value').toLowerCase()))
            $correctionDataCheckbox.enable()
            selectize.disable()
            selectize.clear(true)

    $planDetail.on 'change', ->
        getPlanDetails()
        updateExternalServiceAvailability hasAdminRights

    $useExternalServiceSelectize.on 'change', ->
        getPlanDetails()

    $saveButton.on 'click', saveCustomerDetails

    $startDateTimePicker.on 'dp.change', ->
        selectedDate = startDateTimePicker.date()
        if selectedDate and hasAdminRights
            startDate = selectedDate
            daysLicensingPeriodSelectize.enable()
            monthsLicensingPeriodSelectize.enable()
            yearsLicensingPeriodSelectize.enable()

            days = parseInt daysLicensingPeriodSelect.val()
            months = parseInt monthsLicensingPeriodSelect.val()
            years = parseInt yearsLicensingPeriodSelect.val()

            if (days + months + years) > 0
                endDateTimePicker.date startDate.add(years, 'years').add(months, 'months').add(days, 'days')
            else
                endDateTimePicker.clear()
        else
            startDate = startDateTimePicker.date()
            endDateTimePicker.clear()
            daysLicensingPeriodSelectize.disable()
            monthsLicensingPeriodSelectize.disable()
            yearsLicensingPeriodSelectize.disable()
        checkSaveState()

    runtimeSelectionChanged = ->
        startDate = startDateTimePicker.date()
        daysSelection = $daysDropdown.val()
        monthsSelection = $monthsDropdown.val()
        yearsSelection = $yearsDropdown.val()

        days = parseInt daysSelection
        months = parseInt monthsSelection
        years = parseInt yearsSelection

        if renewalSelected($daysDropdown, $monthsDropdown, $yearsDropdown)
            if planId
                endDate = defaultEndDate.clone()
                endDateTimePicker.date endDate.add(years, 'years').add(months, 'months').add(days, 'days')
            else
                endDateTimePicker.date startDate.add(years, 'years').add(months, 'months').add(days, 'days')
        else
            if planId
                endDateTimePicker.date defaultEndDate
            else
                endDateTimePicker.clear()
        checkSaveState()

    daysLicensingPeriodSelectize.on 'change', runtimeSelectionChanged
    monthsLicensingPeriodSelectize.on 'change', runtimeSelectionChanged
    yearsLicensingPeriodSelectize.on 'change', runtimeSelectionChanged

    $promise.done updateExternalServiceAvailability hasAdminRights

    unless planId
        checkSaveState $ctx

renewalSelected = ($daysDropdown, $monthsDropdown, $yearsDropdown) ->
    days = parseInt $daysDropdown.val()
    months = parseInt $monthsDropdown.val()
    years = parseInt $yearsDropdown.val()

    return (days + months + years) > 0

updateMap = ->
    radius = $('#radius').val()
    map = getByName 'RTKMap'
    unless map
        return
    source = map.layerManager.layers.webgis()[0].getSource()
    features = source.getFeatures()
    source.on 'addfeature', -> checkSaveState()
    source.on 'removefeature', -> checkSaveState()

    unless features.length isnt 0
        return

    features[0].set 'radius', radius
    radiusPlugin = map.plugins[map.plugins.length - 1]
    radiusPlugin.pointMoved
        feature: features[0]

saveCustomerDetails = ->
    bottomFormValid = $('#detailedCustomerForm').valid()
    planSettingsValid = $('#plan-settings').valid()
    $licenseBeginDatePicker = $('#licenseBegin')
    $licenseEndDatePicker = $('#licenseEnd')

    currentNumberOfLicenses = $('#current-number-of-licenses').val()

    selectedNumberOfLicenses = $('#NumberOfPosChanges').val()

    #todo check Grid number of Rows with NumberOfLicences to check it its valid
    return unless bottomFormValid and planSettingsValid

    url = $('#saveCustomerDetails').data 'url'
    planType = $('#PlanType').val()
    grid = $('#customerLoginsGrid').staticgrid()[0]
    map = getByName 'RTKMap'
    position = null
    if map
        features = map.webgis.getFeatures()
        states = map.webgis.getModifiedGeometriesWKT()
        if features.length
            format = new WKT()
            opt =
                dataProjection: 'EPSG:4326'
                featureProjection: 'EPSG:900913'
            position = format.writeFeature features[0], opt


    model =
        PlanId: $('#planId').val()
        entityId: $('#EntityId').val() or $('#hiddenEntityId').val()
        PlanType: planType
        LicenseBegin: $licenseBeginDatePicker.data('DateTimePicker').date().startOf('day')
        LicenseEnd: $licenseEndDatePicker.data('DateTimePicker').date().startOf('day')
        Days: $('#days-dropdown').val()
        Months: $('#months-dropdown').val()
        Years: $('#years-dropdown').val()
        NumberOfLicences: $('#NumberOfLicences').val()
        Radius: $('#radius').val()
        NumberOfPosChanges: selectedNumberOfLicenses
        CustomerChanges: grid.state.changes()
        PositionStates: states
        noCorrectionData: $('#no-correction-data').prop('checked')
        FKExternalService: $('#external-service').val()
        Position: position

    noLoginsMessage = $('#saveCustomerDetails').data 'no-logins-message'

    if grid.table.data().length is 0
        notifications.warning noLoginsMessage
        return

    promise = antiForgeryPost url, {model}
        .then (res) ->
            if res.duplicateUserNames or res.numberOfLicensesError or res.numberOfLicensesDecreased
                if res.numberOfLicensesDecreased
                    makeWarningHandler($('#saveCustomerDetails').data('licenses-decreased-warning-message'))()
                $('#duplicate-user-names').attr('hidden', true)
                if res.duplicateUserNames
                    $('#duplicate-user-names ul li').remove()
                    $('#duplicate-user-names').removeAttr('hidden')
                    for name in res.duplicateUserNames
                        $('#duplicate-user-names ul').append('<li>' + name + '</li>')
                    makeWarningHandler($('#duplicate-user-names'))()

                $('#invalidPlanType').addClass('invisible')
                $('#numberOfLicensesError').addClass('invisible')
                if res.numberOfLicensesError or res.InvalidPlanType
                    if res.numberOfLicensesError
                        $('#numberOfLicensesError').removeClass('invisible')
                    if res.invalidPlanType
                        $('#invalidPlanType').removeClass('invisible')
                    makeWarningHandler($('#saveCustomerDetails'))()
            else
                makeRefreshHandler()(res)
                makeSuccessHandler($('#saveCustomerDetails'))()
        .then ->
            $gridRenewals = $('#license-renewals').staticgrid()[0]
            $gridRenewals.table.order [0, 'desc']
            .draw()
        .fail makeErrorHandler $('#saveCustomerDetails')

    waitscreen.waitFor promise

getPlanDetails = ->
    $saveButton = $('#saveCustomerDetails')

    $plan = $('#PlanType')
    planType = $plan.val()

    url = $plan.data 'url'
    planId = $('#planId').val()

    $planDetails = $('#planDetails')
    $positionInfoAlert = $('#position-info-alert')

    useExternalServiceSelectize = $('#external-service').selectize()[0].selectize
    selectedItems = useExternalServiceSelectize.items

    unless planType
        $planDetails.children().remove()
        $saveButton.disable()
        return

    url += "&planType=#{planType}"
    if planId
        url += "&planId=#{planId}"

    if selectedItems.length > 0
        url += "&externalServiceId=#{selectedItems[0]}"

    promise = get url
        .done makeReplaceHandler $planDetails
    waitscreen.waitFor promise

    positionInfoUrl = $plan.data 'info-url'
    positionInfoUrl += "?planType=#{planType}"
    if planId
        positionInfoUrl += "&planId=#{planId}"

    promise = get positionInfoUrl
        .done makeReplaceHandler $positionInfoAlert
    waitscreen.waitFor promise

setupMyCustomers = ($ctx) ->
    grid = $ctx.find('#MyCustomersGrid').staticgrid()[0]
    grid.editor.on 'preRemove', ->
        url = $('#deleteUrl').val()
        state = grid.state.changes()
        model =
            customer: state.deleted[0]
        promise = antiForgeryPost url, model, $('form')
            .fail ->
                location.reload()
        waitscreen.waitFor promise

setupDeleteRTKPlan = ($ctx) ->
    $deleteButton = $ctx.find('#delete')
    url = $deleteButton.data 'url'
    $modal = $ctx.find('#delete-modal')

    $deleteButton.on 'click', ->
        $modal.modal('hide')

        data =
            planId: $('#PlanId').val()
            entityId: $('#EntityId').val()

        promise = antiForgeryPost url, data
            .then makeReplaceHandler $('#content')
            .done makeSuccessHandler $deleteButton
            .fail makeErrorHandler $deleteButton
        waitscreen.waitFor promise

setupLogins = ($ctx, promise) ->
    $grid = $ctx.find('#customerLoginsGrid').staticgrid()[0]
    numberOfLicencesDropdown = $ctx.find('#NumberOfLicences')
    $availableVehicles = $ctx.find('#available-vehicles')
    $isReadonly = $ctx.find('#is-readonly')
    $planDetails = $('#planDetails')
    planType = $('#PlanType').val()
    useExternalServiceSelectize = $('#external-service').selectize()[0].selectize
    selectedItems = useExternalServiceSelectize.items
    hasAdminRights = JSON.parse($('#hasAdminRights').data('value').toLowerCase())
    useExternalService = false

    if selectedItems.length > 0
        externalServiceOption = useExternalServiceSelectize.options[selectedItems[0]]
        useExternalService = JSON.parse externalServiceOption.useexternallogin.toLowerCase()

    $ctx.find('#radius').on 'change', updateMap
    promise.done ->
        updateMap()
    numberOfLicences = parseInt numberOfLicencesDropdown.val()
    countLogins = $grid.table.data().length

    promise.then ->
        checkSaveState()
        isReadonly = JSON.parse ($isReadonly.data 'is-readonly').toLowerCase()

        if isReadonly
            $grid.editor.on 'initEdit', ->
                $grid.editor.show()
                $grid.editor.hide('username')
                $grid.editor.hide('externalUsername')
                $grid.editor.hide('mountpoint')
                $grid.editor.hide('password')
                $grid.editor.hide('port')
                $grid.editor.hide('serverAddress')
                $grid.editor.hide('fkVehicle')
        else
            $grid.editor.on 'preOpen', ->
                text = $planDetails.data('optional-info-description')
                $infoText = $ "<div class='alert alert-info'>#{text}</div>"
                $mountpointField = $grid.editor.node 'mountpoint'

                $infoText.insertBefore $mountpointField

        if(countLogins >= numberOfLicences)
            $grid.editControls.add.$button.disable()

        numberOfLicencesDropdown.on 'change', ->
            numberOfLicences = parseInt numberOfLicencesDropdown.val()
            countLogins = $grid.table.data().length
            checkSaveState()

            if(countLogins >= numberOfLicences)
                $grid.editControls.add.$button.disable()
            else
                $grid.editControls.add.$button.enable()

        $grid.editor.on 'postCreate', ->
            numberOfLicences = parseInt numberOfLicencesDropdown.val()
            countLogins = $grid.table.data().length
            if(countLogins >= numberOfLicences)
                $grid.editControls.add.$button.disable()
            else
                $grid.editControls.add.$button.enable()

            checkSaveState()
        $grid.editor.on 'postRemove', ->
            numberOfLicences = parseInt numberOfLicencesDropdown.val()
            countLogins = $grid.table.data().length
            if(countLogins >= numberOfLicences)
                $grid.editControls.add.$button.disable()
            else
                $grid.editControls.add.$button.enable()

            checkSaveState()

        availableVehicles = JSON.parse $availableVehicles.html()
        allVehicles = null
        $grid.editor.on 'preOpen', (e, mode, action) ->
            field = $grid.editor.field('fkVehicle')
            selectize = field.node().querySelector('select').selectize

            currentVehicle = selectize.options[selectize.items[0]]
            allVehicles = selectize.options
            selectize.clear(true)
            selectize.clearOptions()

            if currentVehicle
                selectize.addOption(currentVehicle)
                selectize.addItem(currentVehicle.value, true)

            for vehicle in availableVehicles
                selectize.addOption(vehicle)
            selectize.refreshOptions(false)

            selectize.on 'change', (value) ->
                if currentVehicle
                    availableVehicles.push(currentVehicle)
                if value
                    currentVehicle = selectize.options[selectize.items[0]]
                availableVehicles = availableVehicles.filter((x) -> x.value isnt value)

            # validation
            $form = $($grid.editor.dom.form)
            validator = $form.validate()
            $form.data 'validator', validator
            $form.clearValidation()
            requiredMsg = $('#validation-messages').data 'msg-rule-required'

            nodePwd = $grid.editor.field('password').node()
            nodePwd.querySelector('input').setAttribute('data-rule-regex', '^[a-zA-Z0-9_?!%&=\\-+]+$')
            errorMsgPassword = $('#validation-messages').data 'msg-rule-password'
            nodePwd.querySelector('input').setAttribute('data-msg-regex', errorMsgPassword)
            nodePwd.querySelector('input').setAttribute('name', 'pwd')

            nodeUserName = $grid.editor.field('username').node()
            nodeUserName.querySelector('input').setAttribute('data-rule-regex', '^[a-zA-Z0-9_?!%&=\\-+]+$')
            errorMsgUsername = $('#validation-messages').data 'msg-rule-username'
            nodeUserName.querySelector('input').setAttribute('data-msg-regex', errorMsgUsername)
            nodeUserName.querySelector('input').setAttribute('name', 'login')

            if planType == '3' && useExternalService && hasAdminRights# external type
                nodeExtPwd = $grid.editor.field('externalPassword').node()
                nodeExtPwd.querySelector('input').setAttribute('data-rule-regex', '^[a-zA-Z0-9_?!%&=\\-+]+$')
                nodeExtPwd.querySelector('input').setAttribute('data-msg-regex', errorMsgPassword)
                nodeExtPwd.querySelector('input').setAttribute('name', 'pwd')

                nodeExtUserName = $grid.editor.field('externalUsername').node()
                nodeExtUserName.querySelector('input').setAttribute('data-rule-regex', '^[a-zA-Z0-9_?!%&=\\-+]+$')
                nodeExtUserName.querySelector('input').setAttribute('data-msg-regex', errorMsgUsername)
                nodeExtUserName.querySelector('input').setAttribute('name', 'login')

                $grid.editor.field('externalUsername').node().querySelector('input').setAttribute('data-rule-required', true)
                $grid.editor.field('externalUsername').node().querySelector('input').setAttribute('name', 'externalUsername')
                $grid.editor.field('externalUsername').node().querySelector('input').setAttribute('data-msg-required', requiredMsg)

                $grid.editor.field('externalPassword').node().querySelector('input').setAttribute('data-rule-required', true)
                $grid.editor.field('externalPassword').node().querySelector('input').setAttribute('name', 'externalPassword')
                $grid.editor.field('externalPassword').node().querySelector('input').setAttribute('data-msg-required', requiredMsg)

            $grid.editor.field('username').node().querySelector('input').setAttribute('data-rule-required', true)
            $grid.editor.field('username').node().querySelector('input').setAttribute('name', 'username')
            $grid.editor.field('username').node().querySelector('input').setAttribute('data-msg-required', requiredMsg)

            $grid.editor.field('password').node().querySelector('input').setAttribute('data-rule-required', true)
            $grid.editor.field('password').node().querySelector('input').setAttribute('name', 'password')
            $grid.editor.field('password').node().querySelector('input').setAttribute('data-msg-required', requiredMsg)

            $grid.editor.field('mountpoint').node().querySelector('input').setAttribute('data-rule-required', true)
            $grid.editor.field('mountpoint').node().querySelector('input').setAttribute('name', 'mountpoint')
            $grid.editor.field('mountpoint').node().querySelector('input').setAttribute('data-msg-required', requiredMsg)

            $grid.editor.field('serverAddress').node().querySelector('input').setAttribute('data-rule-required', true)
            $grid.editor.field('serverAddress').node().querySelector('input').setAttribute('name', 'serverAddress')
            $grid.editor.field('serverAddress').node().querySelector('input').setAttribute('data-msg-required', requiredMsg)

            $grid.editor.field('port').node().querySelector('input').setAttribute('data-rule-required', true)
            $grid.editor.field('port').node().querySelector('input').setAttribute('name', 'port')
            $grid.editor.field('port').node().querySelector('input').setAttribute('data-msg-required', requiredMsg)

        $grid.editor.on 'close', ->
            field = $grid.editor.field('fkVehicle')
            selectize = field.node().querySelector('select').selectize
            selectize.options = allVehicles
            selectize.off 'change'

        $grid.editor.on 'preSubmit', (e, o, action) ->
            formIsValid = $($grid.editor.dom.form).valid()
            unless formIsValid
                e.preventDefault()
                return false

    promise.then -> checkSaveState()

setupRenewLicense = ($ctx) ->
    $daysDropdown = $ctx.find('#days-dropdown')
    $monthsDropdown = $ctx.find('#months-dropdown')
    $yearsDropdown = $ctx.find('#years-dropdown')
    $modal = $ctx.find('#renew-license-modal')
    $renewLicense = $ctx.find('#renew-license')
    $licenseEndDatePicker = $ctx.find('#licenseEnd')
    $licenseEventRenewal = $ctx.find('#license-event-renewal')
    $licenseEventReactivate = $ctx.find('#license-event-reactivate')
    hasAdminRights = JSON.parse($ctx.find('#hasAdminRights').data('value').toLowerCase())
    isLicenseExpired = JSON.parse($ctx.find('#isLicenseExpired').data('value').toLowerCase())
    isLicenseRunning = JSON.parse($ctx.find('#isLicenseRunning').data('value').toLowerCase())
    daysSelect = $daysDropdown.selectize()
    monthsSelect = $monthsDropdown.selectize()
    yearsSelect = $yearsDropdown.selectize()

    daysSelectize = daysSelect[0].selectize
    monthsSelectize = monthsSelect[0].selectize
    yearsSelectize = yearsSelect[0].selectize
    $licenseEventRenewal.disable()

    if hasAdminRights
        if isLicenseExpired
            $licenseEventReactivate.enable()
            $licenseEventRenewal.disable()
            daysSelectize.disable()
            monthsSelectize.disable()
            yearsSelectize.disable()
        else
            $licenseEventReactivate.disable()
            if isLicenseRunning
                daysSelectize.enable()
                monthsSelectize.enable()
                yearsSelectize.enable()

        daysSelectize.on 'change', ->
            if renewalSelected($daysDropdown, $monthsDropdown, $yearsDropdown)
                $licenseEventRenewal.enable()
            else
                $licenseEventRenewal.disable()

        monthsSelectize.on 'change', ->
            if renewalSelected($daysDropdown, $monthsDropdown, $yearsDropdown)
                $licenseEventRenewal.enable()
            else
                $licenseEventRenewal.disable()

        yearsSelectize.on 'change', ->
            if renewalSelected($daysDropdown, $monthsDropdown, $yearsDropdown)
                $licenseEventRenewal.enable()
            else
                $licenseEventRenewal.disable()

        $modal.on 'shown.bs.modal', ->
            question = $('#question').data('question').replace('{days}', $daysDropdown.val())
            question = question.replace('{months}', $monthsDropdown.val())
            question = question.replace('{years}', $yearsDropdown.val())
            $('#question').text question

        $renewLicense.on 'click', ->
            $modal.modal('hide')
            url = $renewLicense.data 'url'
            data =
                entityId: $('#EntityId').val()
                planId: $('#PlanId').val()
                newEndDate: $licenseEndDatePicker.data('DateTimePicker').date().startOf('day')
                days: $daysDropdown.val()
                months: $monthsDropdown.val()
                years: $yearsDropdown.val()

            promise = antiForgeryPost url, data
                .then makeRefreshHandler()
                .done makeSuccessHandler $renewLicense
                .then ->
                    $grid = $('#license-renewals').staticgrid()[0]
                    $grid.table.order [0, 'desc']
                    .draw()
                    $('.nav.nav-tabs a[href="#licenseRenewals"').trigger('click')
                .fail makeErrorHandler $renewLicense

            waitscreen.waitFor promise

setupLicenseReactivate = ($ctx) ->
    $reactivateLicense = $ctx.find('#reactivate-license')
    $licenseBeginDatePicker = $ctx.find('#license-begin')
    $licenseEndDatePicker = $ctx.find('#license-end').data('DateTimePicker')
    $yearsDropdown = $ctx.find('#years-dropdown-reactivation')
    $monthsDropdown = $ctx.find('#months-dropdown-reactivation')
    $daysDropdown = $ctx.find('#days-dropdown-reactivation')
    daysSelect = $daysDropdown.selectize()
    monthsSelect = $monthsDropdown.selectize()
    yearsSelect = $yearsDropdown.selectize()
    $licenseBeginDatePicker.data('DateTimePicker').minDate(moment().startOf('day'))
    $modal = $ctx.find('#reactivation-modal')
    $reactivateLicense.disable()
    $licenseEndDatePicker.clear()
    $reactivateLicense.on 'click', ->
        url = $reactivateLicense.data 'url'
        data =
            entityId: $('#EntityId').val()
            planId: $('#PlanId').val()
            licenseBegin: $licenseBeginDatePicker.data('DateTimePicker').date().startOf('day')
            licenseEnd: $licenseEndDatePicker.date().startOf('day')
            years: parseInt $yearsDropdown.val()
            months: parseInt $monthsDropdown.val()
            days: parseInt $daysDropdown.val()
        $modal.modal('hide')
        promise = antiForgeryPost url, data
            .then makeRefreshHandler()
            .done makeSuccessHandler $reactivateLicense
            .then ->
                $grid = $('#license-renewals').staticgrid()[0]
                $grid.table.order [0, 'desc']
                .draw()
                $('.nav.nav-tabs a[href="#licenseRenewals"').trigger('click')
            .fail makeErrorHandler $reactivateLicense
        waitscreen.waitFor promise

    $licenseBeginDatePicker.on 'dp.change', ->
        years = parseInt $yearsDropdown.val()
        months = parseInt $monthsDropdown.val()
        days = parseInt $daysDropdown.val()
        selectedDate = $licenseBeginDatePicker.data('DateTimePicker').date()
        if selectedDate
            daysSelect.enable()
            monthsSelect.enable()
            yearsSelect.enable()

            $licenseEndDatePicker.date selectedDate.add(years, 'years').add(months, 'months').add(days, 'days')
            if renewalSelected($daysDropdown, $monthsDropdown, $yearsDropdown)
                $reactivateLicense.enable()
            else
                $reactivateLicense.disable()
                $licenseEndDatePicker.clear()
        else
            $reactivateLicense.disable()
            daysSelect.disable()
            monthsSelect.disable()
            yearsSelect.disable()
            $licenseEndDatePicker.clear()

    yearsSelect.on 'change', ->
        years = parseInt $yearsDropdown.val()
        months = parseInt $monthsDropdown.val()
        days = parseInt $daysDropdown.val()

        selectedDate = $licenseBeginDatePicker.data('DateTimePicker').date()
        if selectedDate
            $licenseEndDatePicker.date selectedDate.add(years, 'years').add(months, 'months').add(days, 'days')
            if renewalSelected($daysDropdown, $monthsDropdown, $yearsDropdown)
                $reactivateLicense.enable()
            else
                $reactivateLicense.disable()
                $licenseEndDatePicker.clear()
        else
            $reactivateLicense.disable()
            $licenseEndDatePicker.clear()

    monthsSelect.on 'change', ->
        years = parseInt $yearsDropdown.val()
        months = parseInt $monthsDropdown.val()
        days = parseInt $daysDropdown.val()

        selectedDate = $licenseBeginDatePicker.data('DateTimePicker').date()
        if selectedDate
            $licenseEndDatePicker.date selectedDate.add(years, 'years').add(months, 'months').add(days, 'days')
            if renewalSelected($daysDropdown, $monthsDropdown, $yearsDropdown)
                $reactivateLicense.enable()
            else
                $reactivateLicense.disable()
                $licenseEndDatePicker.clear()
        else
            $reactivateLicense.disable()
            $licenseEndDatePicker.clear()

    daysSelect.on 'change', ->
        years = parseInt $yearsDropdown.val()
        months = parseInt $monthsDropdown.val()
        days = parseInt $daysDropdown.val()

        selectedDate = $licenseBeginDatePicker.data('DateTimePicker').date()
        if selectedDate
            $licenseEndDatePicker.date selectedDate.add(years, 'years').add(months, 'months').add(days, 'days')
            if renewalSelected($daysDropdown, $monthsDropdown, $yearsDropdown)
                $reactivateLicense.enable()
            else
                $reactivateLicense.disable()
                $licenseEndDatePicker.clear()
        else
            $reactivateLicense.disable()
            $licenseEndDatePicker.clear()

setupUpdateLicenseStart = ($ctx) ->
    $updateLicense = $ctx.find('#update-license')
    $licenseBeginDatePicker = $ctx.find('#new-license-begin')

    defaultDate = $licenseBeginDatePicker.data('DateTimePicker').date()
    $licenseEndDatePicker = $ctx.find('#new-license-end')
    $modal = $ctx.find('#set-new-datetime')

    $updateLicense.on 'click', ->
        currentDate = $licenseBeginDatePicker.data('DateTimePicker').date()
        if defaultDate.isSame(currentDate)
            notifications.warning $updateLicense.data 'start-date-not-changed-message'
            return
        url = $updateLicense.data 'url'
        data =
            entityId: $('#EntityId').val()
            planId: $('#PlanId').val()
            licenseBegin: $licenseBeginDatePicker.data('DateTimePicker').date().startOf('day')
            licenseEnd: $licenseEndDatePicker.data('DateTimePicker').date().startOf('day')
        $modal.modal('hide')
        promise = antiForgeryPost url, data
            .then makeRefreshHandler()
            .done makeSuccessHandler $updateLicense
            .then ->
                $grid = $('#license-renewals').staticgrid()[0]
                $grid.table.order [0, 'desc']
                .draw()
                $('.nav.nav-tabs a[href="#licenseRenewals"').trigger('click')
            .fail makeErrorHandler $updateLicense
        waitscreen.waitFor promise

    $licenseBeginDatePicker.on 'dp.change', ->
        selectedDate = $licenseBeginDatePicker.data('DateTimePicker').date()
        if selectedDate
            years = parseInt $('#runtime').data('years')
            months = parseInt $('#runtime').data('months')
            days = parseInt $('#runtime').data('days')

            $licenseEndDatePicker.data('DateTimePicker').date selectedDate.add(years, 'years').add(months, 'months').add(days, 'days')
            $updateLicense.enable()
        else
            $updateLicense.disable()

setupLicenseRenewalsTab = ($ctx) ->
    $grid = $('#license-renewals').staticgrid()[0]
    $grid.table.order [0, 'desc']
        .draw()

register('App.RTK.Administration.SetupCustomerDetails', setupCustomerDetails)
register('App.RTK.Administration.SetupMyCustomers', setupMyCustomers)
register('App.RTK.Administration.SetupDeleteRTKPlan', setupDeleteRTKPlan)
register('App.RTK.Administration.SetupLogins', setupLogins)
register('App.RTK.Administration.SetupRenewLicense', setupRenewLicense)
register('App.RTK.Administration.SetupUpdateLicenseStart', setupUpdateLicenseStart)
register('App.RTK.Administration.SetupLicenseReactivate', setupLicenseReactivate)
register('App.RTK.Administration.SetupLicenseRenewalsTab', setupLicenseRenewalsTab)
